import { Box, Grow, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import tick from "../../asset/Images/tick.png";
import "./success.css";
const style = {
  margin: "auto",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  position: "relative",
};

const SuccessModal = ({ open, handleClose, successTitle }) => {
  setTimeout(() => {
    handleClose();
  }, 5000);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100dvh",
        flexDirection: "column",
      }}
      BackdropProps={{
        style: { backgroundColor: "transparent" },
      }}
    >
      <Grow in={open} timeout={500}>
        <Box sx={style}>
          <CloseIcon
            sx={{
              position: "absolute",
              right: "1%",
              top: "2%",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <Typography variant="h5" className="successTitle">
            {successTitle}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={tick} height="100px" width="auto" />
          </Box>
        </Box>
      </Grow>
    </Modal>
  );
};

export default SuccessModal;
