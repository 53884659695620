import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import "./Modal.css"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 660,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#5F5B5B',
    textAlign: 'center',
    overflow: 'hidden',  // Add this line to hide overflow content
    '@media (max-width: 600px)': {
        width: '90%', // Adjust width for mobile view
    },
};


export default function TransitionsModal() {
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                sx={{ border: 'none' }}
                className="modal-no-outline"
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{ display: "flex", justifyContent: "end", cursor: "pointer", mb: { xs: 2 } }}>
                            <HighlightOffIcon
                                sx={{ backgroundColor: "#a09e6e", px: 1, py: 1, fontSize: { md: 32, xs: 20 }, color: "white" }}
                                onClick={handleClose}
                            />
                        </Box>
                        <Box sx={{ px: "18%" }} className="modal-content">
                            <Box>
                                <h1 className='modal_h1'>Announcement!!</h1>
                            </Box>
                            <Box className="scroll-content">
                                <Box  className="modal_padding">
                                    <Typography className="paramob" >Pearl Towers construction work has begun and is scheduled to be finished in 4.5 years.</Typography>
                                    <Typography><span className='modal_textBold'>Final Call for Exclusive Pricings at Pearl Towers Luxury Apartments!*</span></Typography>
                                    <Typography>This is your final opportunity to capitalize on the<span className='modal_textBold'>*prelaunch prices*</span> at Pearl Towers Luxury Apartments – an unbeatable <span className='modal_textBold'>*Rs 16,500/sqft*!</span></Typography>
                                </Box>
                                <Box sx={{ pb: 2.2 }}>
                                    <Typography>We’re reaching the end of our prelaunch phase, and a new payment plan of <span className='modal_textBold'>*Rs 18,500/sqft*!</span> Will be applicable soon, we want you to benefit from this limited-time pricing advantage.</Typography>
                                </Box>
                                <Box sx={{ pb: 3.5 }}>
                                    <Typography>Feel free to contact us for any queries.</Typography>
                                    <Typography>Regards</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ backgroundColor: "#a09e6e", height: 50 }}></Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
