import React, { useEffect } from "react";
import image1 from "../../asset/Images/card1.jpeg";
import image2 from "../../asset/Images/card2.jpeg";
import image3 from "../../asset/Images/card3.jpeg";
import image4 from "../../asset/Images/card4.jpeg";
import "./Card.css";
import { Container, Grid } from "@mui/material";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const cardData = [
  {
    type: "3 Bed-A Layout",
    backType: "Layout 3 Bed-A",
    title: "3 Bedroom | Attached Bathroom",
    area: "Area: 3197 Sq.ft",
    image: image1,
  },
  {
    type: "4 Bed-A Layout",
    backType: "Layout 3 Bed-A",
    title: "4 Bedroom | Attached Bathroom",
    area: "Area: 3113.25 Sq.ft",
    image: image2,
  },
  {
    type: "3 Bed-B Layout",
    backType: "Layout 3 Bed-A",
    title: "3 Bedroom | Attached Bathroom",
    area: "Area: 2886 Sq.ft",
    image: image3,
  },
  {
    type: "3 Bed-C Layout",
    backType: "Layout 3 Bed-A",
    title: "3 Bedroom | Attached Bathroom",
    area: "Area: 2562.25 Sq.ft",
    image: image4,
  },
];
const Card = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      // Optional customization
    });

    return () => {
      Fancybox.destroy();
    };
  }, []);

  return (
    <Container>
      <Grid container spacing={2} sx={{ marginBottom: "5%" }}>
        {cardData.map((data, index) => (
          <Grid item lg={6} sm={12} xs={12}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img
                    src={data.image}
                    alt="Avatar"
                    style={{ width: "250px", height: "150px" }}
                  />
                  <h3>{data.type}</h3>
                </div>
                <div
                  className="flip-card-back"
                  style={{
                    backgroundImage: `url(${data.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center bottom 50%",
                    cursor: "pointer",
                  }}
                  data-fancybox={`gallery-${index}`}
                  data-src={data.image}
                >
                  <div className="overlayCard"></div>
                  <h2>{data.backType}</h2>
                  <p>
                    {data.title}
                    <br />
                    {data.area}
                  </p>
                  <p>*Double Click to View</p>

                  {/* <p></p> */}
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Card;
