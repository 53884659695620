import React from "react";
import "./Section3.css";
import { Box, Container, Grid, Typography } from "@mui/material";
import icon1 from "../../asset/Icons/g1_icon1.png";
import icon2 from "../../asset/Icons/g1_icon2.png";
import icon3 from "../../asset/Icons/g1_icon3.png";
import icon4 from "../../asset/Icons/g1_icon4.png";
import icon5 from "../../asset/Icons/g1_icon5.png";
import icon6 from "../../asset/Icons/g1_icon6.png";
import icon7 from "../../asset/Icons/g1_icon7.png";
import icon8 from "../../asset/Icons/g1_icon8.png";
import icon9 from "../../asset/Icons/g1_icon9.png";
import icon10 from "../../asset/Icons/g1_icon10.png";
import icon11 from "../../asset/Icons/g1_icon11.png";
import icon12 from "../../asset/Icons/g1_icon12.png";
import icon13 from "../../asset/Icons/g1_icon13.png";
import icon14 from "../../asset/Icons/g1_icon14.png";
import icon15 from "../../asset/Icons/g1_icon15.png";

const Section3 = () => {
  return (
    <Container
      fluid
      sx={{ mt: 10, backgroundColor: "#484848" }}
      maxWidth={false}
    >
      <Grid container paddingY={3} columnSpacing={2}>
        <Grid
          item
          md={1}
          lg={1.5}
          sm={12}
          className="sec3_borderclassone sec3_grid1"
        >
          <Box className="">
            <p className="sec3_ptag">
              Premium Quality Housing with Cutting Edge Amenities.
            </p>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2.1}
          sm={2.4}
          xs={2.4}
          sx={{ paddingLeft: { xs: "0px !important" } }}
          className="sec3_borderclass"
        >
          <Box className="sec3_boxMain">
            <Box sx={{ width: "100%" }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon1} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Gymnasium
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon2} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Signature Café
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 5 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon3} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Rooftop Garden
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2.1}
          sm={2.4}
          xs={2.4}
          sx={{ paddingLeft: { xs: "0px !important" } }}
          className="sec3_borderclass"
        >
          <Box className="sec3_boxMain">
            <Box sx={{ width: "100%" }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon4} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Infinity Pool
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon5} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Pharmacy
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon6} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Ladies & Gents Lounge
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={2}
          lg={2.1}
          sm={2.4}
          xs={2.4}
          sx={{ paddingLeft: { xs: "0px !important" } }}
          className="sec3_borderclass"
        >
          <Box className="sec3_boxMain">
            <Box sx={{ width: "100%" }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon7} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                High End Security Surveillance
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon8} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Contemporary Design
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon9} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Grocery Store
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2.1}
          sm={2.4}
          xs={2.4}
          sx={{ paddingLeft: { xs: "0px !important" } }}
          className="sec3_borderclass"
        >
          <Box className="sec3_boxMain">
            <Box sx={{ width: "100%" }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon10} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                High End Reception
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon11} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                24/7 Operational Lifts
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon12} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Relaxation Corner
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          lg={2.1}
          sm={2.4}
          xs={2.4}
          sx={{ paddingLeft: { xs: "0px !important" } }}
        >
          <Box className="sec3_boxMain">
            <Box sx={{ width: "100%" }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon13} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Spacious Lobbies
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon14} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Ample Parking Space
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 4 }} className="sec3_colCenter">
              <Box pb={0} className="sec3_imgBox">
                <img className="sec3_img1 img_trans" src={icon15} />
              </Box>
              <Typography className="sec3_typo" sx={{ mt: { md: 3, xs: 1 } }}>
                Community Center
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Section3;
