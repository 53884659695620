import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Modal from "../Components/Modal/Modal";

const Layout = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 3000);
    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, []);
  return (
    <>
      {showModal && <Modal />}
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
