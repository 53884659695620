import React from 'react';
import "./Button.css";
import { useNavigate } from 'react-router-dom';

const ReButton = ({ text, path }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (path) { navigate(path); }
    };
    return (
        <>
        <div className='center_btn_div'>
            <button className='res_btn res_btn_anim1' onClick={handleClick}>{text}</button>
            </div>
        </>
    );
}

export default ReButton;
