import { Container } from "@mui/material";
import React from "react";
import "./PaymentPlan.css";
import paymentIMG from "../../asset/Images/paymentDetail.jpeg";

const PaymentPlans = () => {
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <img src={paymentIMG} className="paymentIMG" />
    </Container>
  );
};

export default PaymentPlans;
