import React, { useRef } from "react";
import "./apartmentLayout.css";
import Card from "./Card";
import PaymentPlans from "./PaymentPlans";
import CarouselSlider from "./CarouselSlider";
const ApartmentLayout = () => {
  const cardPlansRef = useRef(null);
  const paymentPlansRef = useRef(null);

  const scrollToCard = () => {
    if (cardPlansRef.current) {
      cardPlansRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToPayment = () => {
    if (paymentPlansRef.current) {
      paymentPlansRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {" "}
      <div className="layoutWrapper">
        <div className="overlay"></div>
        <h2 onClick={scrollToCard}>Our Apartment Layouts</h2>
        <div ref={cardPlansRef}>
          <Card />
        </div>
      </div>
      <div
        className="layoutWrapper customMargin"
        style={{ marginTop: "-3.4%" }}
      >
        <div className="overlay"></div>
        <h2 className="paymentPlanTitle" onClick={scrollToPayment}>
          Our Affordable Payment Plans!
        </h2>
        <div ref={paymentPlansRef}>
          <PaymentPlans />
        </div>
        <CarouselSlider />
      </div>
    </>
  );
};

export default ApartmentLayout;
