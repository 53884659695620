// api.js
import axios from "axios";

const postRequest = async (payload) => {
  try {
    const response = await axios.post(
      "https://ielndhigdmvdmbv7iz6x6tzpby0efttx.lambda-url.ap-southeast-1.on.aws",
      null,
      {
        params: payload,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export default postRequest;
