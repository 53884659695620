import React, { useState } from "react";
import "./footer.css";
import logo from "../../asset/Images/footer-logo.png";
import hashooGroup from "../../asset/Images/hashhoGroupLogo.png";
import cdcimg from "../../asset/Images/CDC-logo.png";
import askari11 from "../../asset/Images/askari11.png";
import CallIcon from "@mui/icons-material/Call";
import DraftsIcon from "@mui/icons-material/Drafts";
// import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import InstagramIcon from '@mui/icons-material/Instagram';
import { Container, Grid, TextField, Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NewsLetter from "../../Components/NewsLetter/NewsLetter";
import postRequest from "../../Components/api/PostRequest";
import MoonLoader from "react-spinners/ClipLoader";
import SuccessModal from "../../Components/successModal";

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const validatePhoneNumber = (phoneNumber) => {
  const re = /^(0\d{10}|(\+92\d{10}))$/;
  return re.test(String(phoneNumber));
};

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Email is not valid";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number is not valid";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.phoneNumber,
      message: formData.message,
    };

    try {
      setLoader(true);
      const data = await postRequest(payload);
      setLoader(false);
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
      setOpenSuccess(true);
      setErrors({});
      console.log("Success:", data);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpenSuccess(false);
  };
  return (
    <>
      <NewsLetter />
      <div className="footer_main_div">
        <SuccessModal
          open={openSuccess}
          handleClose={handleClose}
          successTitle="Form submitted Successfully"
        />
        <div className="footerContainerDiv">
          <div className="sec33_borderclass">
            <Grid container>
              <Grid item xs={12} md={4.5}>
                <div className="footer_logo">
                  <img src={logo} alt="img" width={300} />
                </div>
                <div className="logo_sections">
                  <img src={hashooGroup} alt=" " />
                  <img src={askari11} alt="" />
                  <img src={cdcimg} alt="" />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="footer_sec_section">
                  <div className="footer_section_first">
                    <h4>Quick Links</h4>
                    <p> Download Apartment Layouts</p>
                    <p>Download Payment Details</p>
                  </div>
                  <div className="iconText">
                    <a href="tel:+923210111222">
                      <CallIcon /> <p> +92 321 0111222</p>
                    </a>
                  </div>
                  <div className="iconText">
                    <a href="mailto:info@pearltower.com.pk" target="_blank">
                      <DraftsIcon /> <p> info@pearltower.com.pk</p>
                    </a>
                  </div>
                  <div className="iconText">
                    <a
                      href="https://www.google.com/maps/place/Pearl+Towers/@31.463001,74.4296976,15z/data=!4m6!3m5!1s0x391909ab08cb91db:0xb31864b948069e89!8m2!3d31.463001!4d74.4296976!16s%2Fg%2F11v02vm0xz?hl=en-GB&entry=ttu"
                      target="_blank"
                    >
                      {" "}
                      <LocationOnIcon />{" "}
                      <p> Pearl Towers, Rd A, Askari XI, Lahore</p>
                    </a>
                  </div>
                  <div className="map_div">
                    <div className="google_maps">
                      <iframe
                        src="https://www.google.com/maps/embed?origin=mfe&pb=!1m4!2m1!1spearl+towers+rd+A,+Askari+XI!5e0!6i11"
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="footer_section_first">
                  <h4>Get in Touch with us !</h4>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid item xs={12} md={12}>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="Name"
                          variant="filled"
                          size="small"
                          className="inputFieldother"
                          fullWidth
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          disabled={loader}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-small"
                          placeholder="Email"
                          variant="filled"
                          size="small"
                          className="inputFieldother"
                          fullWidth
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          disabled={loader}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="Your Phone Number"
                        variant="filled"
                        size="small"
                        className="inputFieldother"
                        fullWidth
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        disabled={loader}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber}
                      />
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="Message"
                        variant="filled"
                        size="small"
                        multiline
                        rows={3}
                        fullWidth
                        className="inputFieldother"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        disabled={loader}
                      />
                      {loader ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "2%",
                          }}
                        >
                          <MoonLoader color="#A09E6F" />
                        </div>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          className="submitBtnfooter"
                        >
                          Send
                        </Button>
                      )}
                      {/* <div style={{display:"flex", gap:"2rem", justifyContent:"center", textAlign:"center", padding:"20px"}}>
                    <FacebookRoundedIcon/>
<WhatsAppIcon/>
<InstagramIcon/>
</div> */}
                    </Grid>
                  </form>
                </div>
              </Grid>
              <Grid item xs={12} md={0.5}></Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="footer_last_div">
        <Container>
          <div className="footer_last_div_section">
            <p>
              {" "}
              All Rights Reserved by Pearl Towers Official{" "}
              <span className="footer_span"> | Privacy Policy | </span> Designed
              and Developed by{" "}
              <a href="https://www.xcentricservices.com/" target="_blank">
                Xcentric Services.
              </a>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
