import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { routes } from "./Routes/Routes";
import Layout from "./Layout/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map((obj) => {
            return (
              <Route
                key={obj.path}
                path={obj.path}
                element={<obj.component />}
              />
            );
          })}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
