import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Header.css";
import ReButton from "../../Components/ReusableComponent/Button/Button";
import logoheader from "../../asset/Images/logo-enhanced-copy-150x150.png";
import { Modal, Grow, Slide } from "@mui/material";
import ModalForm from "./ModalForm";
import sphere from "../../asset/Images/sphere.png";

const pages = [
  "Home",
  "About",
  "Payment Plan",
  "Layouts",
  "Gallery",
  "Contact Us",
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const [open, setOpen] = useState(false);
  const [hovering, setHovering] = useState(false);
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const style = {
    margin: "auto",
    width: 500,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    zIndex: 20 /* Ensure it appears above the background */,
    px: 8,
    pr: 20,
    pt: 1,
    pb: 6,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMouseMove = (event) => {
    if (hovering) {
      const x = (event.clientX / window.innerWidth - 0.5) * 2;
      const y = (event.clientY / window.innerHeight - 0.5) * 2;

      const styleElement = document.createElement("style");
      styleElement.type = "text/css";
      styleElement.innerHTML = `
      :root {
        --bg-x: ${x * 5}%; /* Adjust multiplier for desired movement */
        --bg-y: ${y * 5}%; /* Adjust multiplier for desired movement */
      }
    `;
      document.head.appendChild(styleElement);
    }
  };

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#7272722B" }}>
        <Container maxWidth="lg">
          <Toolbar
            disableGutters
            sx={{
              py: 0.8,
              px: { md: 5 },
              display: { md: "flex", xs: "block" },
            }}
          >
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },

                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={logoheader} alt="img" height={75} />
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                width: "100%",
                mr: 2,
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={logoheader} alt="img" height={75} />
            </Typography>
            <Box
              sx={{
                display: { md: "none", xs: "flex" },
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: { xs: "flex", md: "none" }, mr: 2 }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="#484848"
                >
                  <MenuIcon
                    sx={{ backgroundColor: "#A09E6E", color: "white" }}
                  />
                </IconButton>
                <Box sx={{ width: "100%" }}>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                    PaperProps={{
                      sx: {
                        width: "100%", // Set the width to 100%
                      },
                    }}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu} href="/">
                        <Typography
                          textAlign="center"
                          sx={{ textTransform: "capitalize" }}
                          className="headerMenu"
                           href="/"
                        >
                          {page}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
              {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
              <Typography
                variant="h5"
                noWrap
                component="a"
              
                sx={{
                  mr: 2.5,
                  display: { xs: "flex", md: "none" },
                  // flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <WhatsAppIcon
                  sx={{ backgroundColor: "#25D366", padding: 0.3 }}
                />
              </Typography>
              <Box sx={{ flexGrow: 0, display: { md: "none", xs: "block" } }}>
                <a href="tel:+923210111222">
                  {" "}
                  <WifiCalling3Icon
                    sx={{ color: "#a09e6e", fontSize: { md: "45px" } }}
                  />{" "}
                </a>
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "space-evenly",
                pr: { md: 8 },
                border: "none",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#484848",
                    display: "block",
                    textTransform: "capitalize",
                    px: { md: 2.5 },
                    borderBottom: "3px solid transparent",
                  }}
                  className="header_btns"
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box
              sx={{ flexGrow: 0, display: { xs: "none", md: "block" } }}
              onClick={handleOpen}
            >
              <ReButton text="Book An Appointment" />
            </Box>
            <Box
              sx={{ flexGrow: 0, display: { md: "block", xs: "none" } }}
              className="phoneIconHeader"
            >
              <a href="tel:+923210111222">
                {" "}
                <WifiCalling3Icon
                  sx={{
                    color: "#a09e6e",
                    fontSize: { md: "45px" },
                    paddingLeft: "22px",
                  }}
                />{" "}
              </a>
            </Box>
          </Toolbar>
          <Grow in={open} timeout={500}>
            <div
              className="modalBackground"
              style={open ? { display: "flex" } : { display: "none" }}
            >
              <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100dvh",
                  flexDirection: "column",
                }}
                BackdropProps={{
                  style: { backgroundColor: "transparent" },
                }}
              >
                <Grow in={open} timeout={500}>
                  <Box
                    sx={{ ...style, position: "relative" }}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="modalContent">
                      <img
                        src={sphere}
                        className="sphereIMG"
                        height="80px"
                        width="80px"
                      />
                      <ModalForm />
                    </div>
                  </Box>
                </Grow>
              </Modal>
            </div>
          </Grow>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
