import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Container, Grid } from "@mui/material";
import tower1 from "../../asset/Images/tower1.jpeg";
import tower2 from "../../asset/Images/tower2.jpeg";
import tower3 from "../../asset/Images/tower3.jpeg";
import tower4 from "../../asset/Images/tower4.jpeg";
import tower4c from "../../asset/Images/tower4c.jpeg";
import tower5 from "../../asset/Images/tower5.jpeg";
import tower5c from "../../asset/Images/tower5c.jpeg";
import tower6 from "../../asset/Images/tower6.jpeg";
import "./slider.css";
import Button from "../ReusableComponent/Button/Button";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const carouselData = [
  { id: 1, img: tower1 },
  { id: 2, img: tower2 },
  { id: 3, img: tower3 },
  { id: 4, img: tower4 },
  { id: 5, img: tower4c },
  { id: 6, img: tower5 },
  { id: 7, img: tower5c },
  { id: 8, img: tower6 },
];

const CarouselSlider = () => {
  return (
    <>
      <Container
        className="slider-container"
        style={{ position: "relative", zIndex: 5 }}
      >
        <Slider {...settings} className="shadowBox">
          {carouselData.map((data) => (
            <div key={data.id}>
              <img src={data.img} className="carouselIMG" />
            </div>
          ))}
        </Slider>
      </Container>
      <Box
        sx={{ paddingBottom: "8%", display: "flex", justifyContent: "center" }}
      >
        <Button text="Contact Us" />
      </Box>
    </>
  );
};

export default CarouselSlider;
