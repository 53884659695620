import React from "react";
import "./Section1.css";
import { Box, Container, Grid } from "@mui/material";
import sec1_img1 from "../../asset/Images/sec1_img1.webp";
import ReButton from "../ReusableComponent/Button/Button"

const Section1 = () => {
  return (
    <>
      <Container sx={{ mb: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ pr: { md: 3 }, order: { xs: 2, md: 1 } }}>
            <Box>
              <h2 className='sec1_g1_h2'><a href="" className='sec1_g1_a'>About Pearl Towers</a></h2>
              <Box className='' sx={{ pr: { md: 3 }, pl: { md: 0 }, paddingX: { xs: 2 } }}>
                <p className='sec1_g1_p1'>Hashoo Group has solidified its position as the foremost conglomerate in Pakistan, celebrated for its varied business pursuits. It is recognized as the preeminent hospitality group in the country, overseeing the prestigious Pearl-Continental Hotels, PC Legacy, and Hotel One across the nation.</p>
                <p className='sec1_g1_p1'>Demonstrating a commitment to innovation and advancement, Hashoo Group is actively broadening its scope with the introduction of Askari 11, a venture dedicated to crafting outstanding real estate experiences. The collaboration between Hashoo Group and Askari 11 underscores an unyielding dedication to the creation of exceptional living spaces that embody fundamental values of excellence and distinction.</p>
                <p className='sec1_g1_p1'>With years of delivering premium living experience  Hashoo Group is Launching Pearl Towers in 2024.</p>
              </Box>
              <Box>
                <ReButton text="Book Before Launch!" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <img className='sec1_img1' src={sec1_img1} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Section1;
