import React from 'react'
import "./Section4.css"
import { Box, Container, Grid } from '@mui/material'
import sec4_img1 from "../../asset/Images/sec4_img1.jpeg"
import sec4_img2 from "../../asset/Images/sec4_img2.jpeg"

const Section4 = () => {
    return (
        <Container sx={{ mt: { md: 8, xs: 8 } }}>
            <Grid container spacing={6}>
                <Grid item md={6} sm={12} xs={12} className='sec4_g1'>
                    <img className='sec4_img1 sec4_imgBounce' src={sec4_img1} />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <Box>
                        <h2 className='sec1_g1_h2' style={{ lineHeight: "1.1em" }}><a href="" className='sec1_g1_a'>Our Amenities Include Everything you Need!</a></h2>
                    </Box>
                    <Box>
                        <p className='sec1_g1_p1'>Apart from an optimal location and attractive interior designs, our Pearl Towers boast a plethora of community amenities. Even your beloved pets will enjoy their time at Pearl Towers Lahore. The meticulously maintained grounds provide a picturesque backdrop, complemented by pet-friendly spaces that cater to your furry family members. Enjoy leisurely strolls along the walking paths or gather with friends and neighbors in the inviting outdoor spaces. Planned resident events add a social dimension to your lifestyle, fostering a sense of community. Indoors, a stylish resident lounge awaits, providing a comfortable and chic retreat for residents to unwind. At Pearl Towers, we prioritize both the aesthetics and functionality of your living environment, ensuring a harmonious blend of comfort and community.</p>
                    </Box>
                    <Box sx={{ mt: 5, mb: 2 }}>
                        <img className='sec1_img1 sec4_imgBounce' src={sec4_img2} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Section4