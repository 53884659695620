import React from "react";
import Section1 from "../../Components/Section1/Section1";
import Section2 from "../../Components/Section2/Section2";
import Section3 from "../../Components/Section3/Section3";
import HeroBanner from "../../Components/Herosection/index";
import ApartmentLayout from "../../Components/ApartmentLayout";
import Section4 from "../../Components/Section4/Section4";
const Home = () => {
  return <div>
    <HeroBanner />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <ApartmentLayout />
  </div>;
}
export default Home;
