import React, { useState } from "react";
import styles from "./style.css";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import SuccessModal from "../successModal";
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
const NewsLetter = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");

  const handleSubscription = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is not valid";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setOpenSuccess(true);
    setErrors({});
    setEmail("");
  };
  const handleClose = () => {
    setOpenSuccess(false);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <div className="footerContainerDiv">
      <SuccessModal
        open={openSuccess}
        handleClose={handleClose}
        successTitle="Subscribed to Newsletter Successfully"
      />
      <div className="newsLetterSection">
        <Grid container spacing={6} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6}>
            <h3 className="headingThree">
              Get Updates And Stay Connected -Subscribe <br /> To Our Newsletter
            </h3>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="textButtonField">
              <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                placeholder="Email"
                variant="filled"
                size="small"
                className="inputFieldnews phoneFieldd"
                value={email}
                onChange={handleEmailChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              <Button
                variant="contained"
                className="submitBtnNewsLetter"
                onClick={handleSubscription}
              >
                Subscribe
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NewsLetter;
