import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "./ModalForm.css";
import ReButton from "../../Components/ReusableComponent/Button/Button";
import postRequest from "../../Components/api/PostRequest";
import MoonLoader from "react-spinners/ClipLoader";
import SuccessModal from "../../Components/successModal";

const interest = [
  { id: 1, label: "Tower 1: 4 Bed -A, Area: 3,113.25 sq.ft" },
  { id: 2, label: "Tower 2: 4 Bed -A, Area: 3,113.25 sq.ft" },
  { id: 3, label: "Tower 3: 3 Bed -B, Area: 2,562.50 sq.ft" },
  { id: 4, label: "Tower 4: 3 Bed -A, Area: 3,197.25 sq.ft" },
  { id: 5, label: "Tower 4: 3 Bed -C, Area: 2,804.25 sq.ft" },
  { id: 6, label: "Tower 5: 3 Bed -A, Area: 3,197 sq.ft" },
  { id: 7, label: "Tower 5: 3 Bed -C, Area: 2,886 sq.ft" },
  { id: 8, label: "Tower 6: 3 Bed -B, Area: 2,562.25 sq.ft" },
];
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
const ModalForm = () => {
  const [selectInterest, setSelectInterest] = useState("Layout of Interest*");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+92");
  const [loader, setLoader] = useState(false);
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const [errors, setErrors] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event);
  };
  const handleInterestChange = (event) => {
    setSelectInterest(event.target.value);
  };
  const handleSubmit = async () => {
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is not valid";
    }
    if (selectInterest === "Layout of Interest*") newErrors.interest = "Interest is required";
    if (!phoneNumber || phoneNumber === "+92") newErrors.phoneNumber = "Phone number is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    setErrors({});
    const payload = {
      name,
      email,
      phone: phoneNumber,
      interest: selectInterest,
    };

    try {
      setLoader(true);
      const data = await postRequest(payload);
      setSelectInterest("Layout of Interest*");
      setName("");
      setEmail("");
      setPhoneNumber("+92"); // Reset to Pakistan country code after submission
      setLoader(false);
      setOpenSuccess(true);
      console.log("Success:", data);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  
  const handleClose = () => {
    setOpenSuccess(false);
  };
  return (
    <div>
      <SuccessModal
        open={openSuccess}
        handleClose={handleClose}
        successTitle="Form Submitted Successfully"
      />
      <h2 className="TitleForm">Book Your Appointment</h2>
      <h3 className="subtitleForm">
        A representative of our team will contact you!
      </h3>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <TextField
            id="filled-hidden-label-small"
            placeholder="Full Name"
            variant="filled"
            size="small"
            className="formInput"
            fullWidth
            value={name}
            onChange={handleNameChange}
            disabled={loader}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item lg={6}>
          <TextField
            id="filled-hidden-label-small"
            placeholder="Email"
            variant="filled"
            size="small"
            className="formInput"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            disabled={loader}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "5%" }}>
        <PhoneInput
          country={"pk"}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          inputStyle={{ width: "100%" }}
          disabled={loader}
        
        />
        {!!errors.phoneNumber && (
          <Typography variant="caption" color="error">
            {errors.phoneNumber}
          </Typography>
        )}
      </Box>
      <FormControl
        variant="filled"
        size="small"
        className="inputField interestField"
      >
        <Select
          value={selectInterest}
          onChange={handleInterestChange}
          inputProps={{ id: "select-interest" }}
          renderValue={(value) => value}
          disabled={loader}
        >
          {interest.map((intrst) => (
            <MenuItem key={intrst.id} value={intrst.label}>
              {intrst.label}
            </MenuItem>
          ))}
        </Select>
        {errors.interest && (
          <Typography variant="caption" color="error">
            {errors.interest}
          </Typography>
        )}
      </FormControl>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
          }}
        >
          <MoonLoader color="#A09E6F" />
        </div>
      ) : (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
          onClick={handleSubmit}
        >
          <ReButton text="Book Now" />
        </Box>
      )}
    </div>
  );
};

export default ModalForm;
