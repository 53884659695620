import React from 'react'
import "./Section2.css"
import { Box, Container, Grid } from '@mui/material'
import sec2_img1 from "../../asset/Images/sec2_img1.jpeg"
import sec2_img2 from "../../asset/Images/sec2_img2.jpeg"
import sec2_img3 from "../../asset/Images/sec2_img3.jpeg"
import ReButton from '../ReusableComponent/Button/Button'


const Section2 = () => {
    return (
        <Container sx={{ marginTop: 15 }}>
            <Box sx={{ textAlign: "center", marginBottom: { md: 10 } }}>
                <h2 className='sec2_box1_h2'>Lahore's Finest Residential Apartments</h2>
            </Box>
            <Box>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Box>
                            <h2 className='sec1_g1_h2'><a href="" className='sec1_g1_a'>Explore Pearl Towers and Discover Your Dream Home!</a></h2>
                            <Box className='' sx={{ pr: { md: 3 }, pl: { md: 0 }, px: { xs: 2 } }}>
                                <p className='sec1_g1_p1'>At Pearl Towers our comprehensive range of services encompasses contemporary design with elegant finishing, creating an aesthetic and modern living experience. Our smart facilities are equipped with state-of-the-art features, ensuring a high-quality lifestyle for our residents. The community center provides a luxurious space for social gatherings and events. Our residential apartments boast luxury and sophistication, complemented by high-end security surveillance for utmost safety.</p>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <img className='sec1_img1' src={sec2_img1} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img className='sec1_img1 bounce_style_sec1' src={sec2_img2} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <h2 className='sec1_g1_h2' style={{ lineHeight: "1.1em" }}><a href="" className='sec1_g1_a'>We are offering top notch residential facilities which makes us stand out in the market!</a></h2>
                        </Box>
                        <Box>
                            <p className='sec1_g1_p1'>The property offers ample parking space, spacious lobbies, and amenities such as a signature café and grocery store with a pharmacy. The high-end reception area sets the tone for a premium living environment. Operational 24/7 lifts enhance accessibility, while rooftop gardens provide a serene escape with relaxing chairs, BBQ, and bonfire facilities.</p>
                        </Box>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <img className='sec1_img1' src={sec2_img3} />
                        </Box>
                        <Box>
                            <h2 className='sec1_g1_h2'><a href="" className='sec1_g1_a'>Perfect Location with Amazing Facilities!</a></h2>
                        </Box>
                        <Box>
                            <p className='sec1_g1_p1'>   Additionally, residents can enjoy exclusive ladies and gents lounges, an infinity pool, and a well-equipped gym with a dedicated relaxation corner. Our commitment is to provide a holistic and refined living experience.</p>
                        </Box>
                        <Box>
                            <ReButton text="Contact Us" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Section2