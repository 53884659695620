import React, { useState } from "react";
import bannerVideo from "../../asset/video/pearlTowersOfficial.mp4";
import "./heroSection.css";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import postRequest from "../api/PostRequest";
import MoonLoader from "react-spinners/ClipLoader";
import SuccessModal from "../successModal";
const countryCodes = [
  { code: "+92", country: "Pakistan" },
  { code: "+1", country: "US/Canada" },
  { code: "+44", country: "UK" },
  { code: "+91", country: "India" },
];
const interest = [
  { id: 1, label: "Tower 1: 4 Bed -A, Area: 3,113.25 sq.ft" },
  { id: 2, label: "Tower 2: 4 Bed -A, Area: 3,113.25 sq.ft" },
  { id: 3, label: "Tower 3: 3 Bed -B, Area: 2,562.50 sq.ft" },
  { id: 4, label: "Tower 4: 3 Bed -A, Area: 3,197.25 sq.ft" },
  { id: 5, label: "Tower 4: 3 Bed -C, Area: 2,804.25 sq.ft" },
  { id: 6, label: "Tower 5: 3 Bed -A, Area: 3,197 sq.ft" },
  { id: 7, label: "Tower 5: 3 Bed -C, Area: 2,886 sq.ft" },
  { id: 8, label: "Tower 6: 3 Bed -B, Area: 2,562.25 sq.ft" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
const HeroBanner = () => {
  const [selectInterest, setSelectInterest] = useState("Layout of Interest*");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+92");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleInterestChange = (event) => {
    setSelectInterest(event.target.value);
  };

  const handleSubmit = async () => {
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is not valid";
    }
    if (selectInterest === "Layout of Interest*")
      newErrors.interest = "Interest is required";
    if (!phoneNumber || phoneNumber === "+92") newErrors.phoneNumber = "Phone number is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({});
    const payload = {
      name,
      email,
      phone: phoneNumber,
      interest: selectInterest,
      message,
    };
    try {
      setLoader(true);
      const data = await postRequest(payload);
      setSelectInterest("Layout of Interest*");
      setName("");
      setEmail("");
      setPhoneNumber("+92");
      setMessage("");
      setLoader(false);
      setOpenSuccess(true);
   
      console.log("Success:", data);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpenSuccess(false);
  };
  return (
    <div className="videoWrapper">
      <div className="overlay"></div>
      <video id="background-video" loop autoPlay muted className="videoComp">
        <source src={bannerVideo} type="video/mp4" />
        Your browser does not support the video tag....
      </video>
      <div className="textContainer">
        <SuccessModal
          open={openSuccess}
          handleClose={handleClose}
          successTitle="Form Submitted Successfully"
        />
        <Container>
          <Grid container spacing={16}>
            <Grid item xs={12} md={6} alignSelf="center">
              <Typography variant="h2" className="heroTitle">
                Distinctive Homes
                <br />
                Timeless Elegance &<br />
                Unmatched Experiences Await
                <br />
                at Pearl Towers Lahore
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="formWrapper">
                <Typography variant="h2">Get in touch!</Typography>
                <Typography variant="p">
                  We would love to hear from you and answer any questions you
                  may have!
                </Typography>
                <div className="nameWrapper">
                  <div className="nameInput">
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-small"
                      placeholder="Name"
                      variant="filled"
                      size="small"
                      className="inputField customWidth"
                      fullWidth
                      value={name}
                      onChange={handleNameChange}
                      disabled={loader}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </div>
                  <div className="nameInput">
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-small"
                      placeholder="Email"
                      variant="filled"
                      size="small"
                      className="inputField customWidth"
                      fullWidth
                      value={email}
                      onChange={handleEmailChange}
                      disabled={loader}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                </div>
                <div className="phoneWrapper">
                  <PhoneInput
                    country={"pk"}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    inputStyle={{ width: "100%" }}
                    disabled={loader}
                  />
                  {errors.phoneNumber && (
                    <Typography variant="caption" color="error">
                      {errors.phoneNumber}
                    </Typography>
                  )}
                </div>
                <FormControl
                  variant="filled"
                  size="small"
                  className="inputField interestField"
                >
                  <Select
                    value={selectInterest}
                    onChange={handleInterestChange}
                    inputProps={{ id: "select-interest" }}
                    renderValue={(value) => value}
                    disabled={loader}
                  >
                    {interest.map((intrst) => (
                      <MenuItem key={intrst.id} value={intrst.label}>
                        {intrst.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.interest && (
                    <Typography variant="caption" color="error">
                      {errors.interest}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  placeholder="Message"
                  variant="filled"
                  size="small"
                  multiline
                  rows={3}
                  fullWidth
                  className="inputField"
                  value={message}
                  onChange={handleMessageChange}
                  disabled={loader}
                />
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "2%",
                    }}
                  >
                    <MoonLoader color="#A09E6F" />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                )}
                <Typography
                  sx={{
                    marginTop: "5%",
                    fontSize: "12px",
                  }}
                >
                  Please fill the form, our team will get back to you soon
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default HeroBanner;
